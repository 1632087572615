import i18n from '@/i18n'

export const runOnNextTick = (fn) => function handler(value, oldValue) {
  this.$nextTick(() => {
    fn.call(this, value, oldValue)
  });
};

export const getRequestMessage = function (type, model, code = 'default') {
  return i18n.t(`request.${type}.${model}.${code}`)
}
export const formatDate = (input) => {
  var datePart = input?.value ? input.value.match(/\d+/g) : input.match(/\d+/g),
  year = datePart[0].substring(2), // get only two digits
  month = datePart[1], day = datePart[2];

  return day+'/'+month+'/'+year;
}

export const getDistinctValues = (settings, attribute) => {
  return [...new Set(settings.map(item => item[attribute]))]
}

export const getOccurences = (arr) => {
  const occurrences = arr.reduce(function (acc, curr) {
    return acc[curr] ? ++acc[curr] : acc[curr] = 1, acc
  }, {});

  return occurrences
}

export const groupBy = (items, key) => items.reduce(
  (result, item) => ({
    ...result,
    [item[key]]: [
      ...(result[item[key]] || []),
      item,
    ],
  }),
  {},
);

export const handleNetworkError = error => {
  if (error.name === 'HTTPError') {
    window.location.reload()
  } else {
    console.error(error.message)
  }
}
