{
  "MARKETS": "Marchés",
  "MARKET": "Marché",
  "OK": "Ok",
  "CANCEL": "Annuler",
  "NAME": "FILE",
  "FROM": "Du",
  "TO": "Au",
  "SUPPLIER_SEARCH": {
    "TITLE": "Fournisseur",
    "NO_SETTINGS": "Aucun paramètrage",
    "DATES": "Jusqu'au ",
    "MARKETS": "Marchés",
    "NO_MARKETS": "Aucun marché",
    "UNKNOWN_MARKETS": "Marché inconnu",
    "RESULTS": "résultats",
    "RESULT": "résultat",
    "NO_RESULTS": "Aucun résultat",
    "AVERAGE_SALES_PRICE": {
      "TITLE": "Prix de vente moyen",
      "N": "Non",
      "O": "Oui"
    },
    "FREQUENCY": {
      "TITLE": "Fréquence",
      "DAY": "Journalière",
      "WEE": "Hebdomadaire",
      "MON": "Mensuelle"
    },
    "CONSOLIDATION": {
      "TITLE": "Consolidation",
      "NONE": "Aucune",
      "MAG": "Magasin",
      "ALL": "Société"
    },
    "SEND_MODES": {
      "TITLE": "Mode d'envoi",
      "PRT": "Portail",
      "EDI": "EDI",
      "NO_DATA": "Aucun mode d'envoi"
    },
    "STAT_CHOICES": {
      "TITLE": "Statistiques",
      "E": "Stocks entrepôts",
      "S": "Stocks",
      "M": "Stocks magasins",
      "V": "Ventes"
    },
    "EXTRACTION_DAY": {
      "1": "Lundi",
      "2": "Mardi",
      "3": "Mercredi",
      "4": "Jeudi",
      "5": "Vendredi",
      "6": "Samedi",
      "7": "Dimanche"
    },
    "SAVE_OK": "Paramètre enregistré",
    "SAVE_KO": "Erreur lors de l'enregistrement du paramètre",
    "DELETE_CONFIRM": "Confirmez la suppression ?",
    "SUPPLIER_PROFILE": "Profil du fournisseur"
  },
  "SUPPLIER_DETAIL": {
    "SETTING": "paramètrages | paramètrages",
    "GDN": "GDN référentiel",
    "GDN_REF": "GDN réf",
    "UNTIL": "JUSQU'AU",
    "MARKETS": "Marchés",
    "CNUF": "CNUF référentiel",
    "CNUF_REF": "CNUF réf",
    "NEGOGROUPS": "Groupes de Négo",
    "ORDER_KEY_LABEL": "Clé de commande",
    "AVERAGE_SALES_PRICE": {
      "TITLE": "Prix de vente moyen",
      "N": "Non",
      "O": "Oui"
    },
    "FREQUENCY": {
      "TITLE": "Fréquence",
      "DAY": "Journalière",
      "WEE": "Hebdomadaire",
      "MON": "Mensuelle",
      "QUA": "Trimestrielle"
    },
    "CONSOLIDATION": {
      "TITLE": "Consolidation",
      "NONE": "Aucune",
      "MAG": "Magasin",
      "ALL": "Société"
    },
    "SETTINGS": {
      "ADD_OK": "Le paramètre a été ajouté.",
      "UPDATE_OK": "Le paramètre a été modifié.",
      "DELETE_OK": "Le paramètre a été effacé.",
      "ADD_ERROR": "Une erreur s'est produite lors de la sauvegarde du paramètrage",
      "UPDATE_ERROR": "Une erreur s'est produite lors de la mise à jour du paramètrage",
      "DELETE_ERROR": ""
    }
  },
  "MODAL": {
    "TITLE": {
      "edit": "Edition du paramètrage",
      "add": "Nouveau paramètrage",
      "show": "Visualisation du paramètrage"
    },
    "CAMPAIGN_PERIOD": "Période de campagne",
    "SUPPLIER_PROFILE": "Profil du fournisseur",
    "SEND_MODE": "Mode d'envoi",
    "STATS": "Statistiques",
    "FREQUENCY": "Fréquence",
    "EXTRACTION_DAY": "Jour d'extraction",
    "CONSOLIDATION": "Consolidation",
    "AVERAGE_PRICE": "Prix de vente moyen",
    "SCHIEVER_SALES": "Ventes Schiever",
    "SECTOR": "Secteur",
    "MARKETS": "Marché",
    "GROUPE_NEGO": "Groupes de Négo",
    "MONDAY": "Lundi",
    "TUESDAY": "Mardi",
    "WEDNESDAY": "Mercredi",
    "THURSDAY": "Jeudi",
    "FRIDAY": "Vendredi",
    "SATURDAY": "Samedi",
    "SUNDAY": "Dimanche",
    "EXTRACTION_DAYS": {
      "1": "du Lundi au dimanche",
      "2": "du Mardi au lundi",
      "3": "du Mercredi au mardi",
      "4": "du Jeudi au mercredi",
      "5": "du Vendredi au jeudi",
      "6": "du Samedi au vendredi",
      "7": "du Dimanche au samedi"
    },
    "NPA_EXTRACTION_DAYS": {
      "1": "Lundi",
      "2": "Mardi",
      "3": "Mercredi",
      "4": "Jeudi",
      "5": "Vendredi",
      "6": "Samedi",
      "7": "Dimanche",
      "M": "Le 5 du mois"
    },
    "DELETE_CONFIRM": "Confirmer la suppression ?",
    "ADD_NEW_SUPPLIER": "Création d'un fournisseur",
    "EDIT_SUPPLIER": "Modifier les données du fournisseur",
    "CASHIER_SWITCH": "Passages caisse",
    "RANKIG_CHOICE_CA": "En CA",
    "RANKIG_CHOICE_QTE": "En quantité",
    "NUMBER_SELL_PER_CASHIER_SWITCH": "Nombre d'articles vendus par passage caisse",
    "RANKING_PRODUCED_PER_SUPPLIER_FOR_EACH": "Ranking produit par produit du fournisseur pour chacun des sites",
    "RANKING_OF_PRODUCTS_TO_GLOBAL_SOCIETY": "Ranking des produits au global société",
    "RANKING_OF_SITES_PER_PRODUCTS": "Ranking des sites par produit",
    "RANKING_OF_SITES_PER_PRODUCT_FORMAT": "Ranking des sites par format de produit"
  },
  "TAG": {
    "E": "Entrepôt",
    "E Entrepôt": "Entrepôt",
    "S": "Stocks",
    "S Stocks": "Stocks",
    "V": "Ventes",
    "V Ventes": "Ventes",
    "PRT": "Portail",
    "EDI": "EDI"
  },
  "RVF": {
    "URL_EXPORT": "https://storage.cloud.google.com/data_out-arf-perfweb-sec/DATAOBS/PRT/",
    "FILE": "Fichier(s)",
    "RAYON": "Rayon",
    "PaymentCNUF": "CNUF Paiement",
    "PaymentGDN": "GDN Paiement",
    "MovementTYPE": "Type",
    "RayonPlaceholder": "Recherche par rayon de paiement",
    "PaymentLabelPlaceholder": "Recherche par libellé de paiement",
    "GDNPlaceholder": "Recherche par GDN de paiement",
    "TypePlaceholder": "Recherche par type de fichier",
    "CNUF": "Recherche par CNUF de paiement",
    "START_DATE": "Date de début",
    "END_DATE": "Date de fin",
    "AllTypes": "Tous les types",
    "Sells": "Ventes",
    "Stocks": "Stock Magasin",
    "StocksE": "Stock Entrepôt",
    "V": "Ventes",
    "S": "Stock Magasin",
    "E": "Stock Entrepôt",
    "AllFrequencies": "Toutes les fréquences",
    "DAILY": "Journaliére",
    "WEEKLY": "Hebdomadaire",
    "MONTHLY": "Mensuelle",
    "QUARTERLY": "Trimestrielle"
  },
  "INFOS": {
    "IFLS": "IFLS",
    "Nom": "Nom du fournisseur",
    "Email": "Email",
    "EmailPlaceholder": "Saisir l'email puis taper Entrer",
    "Profile": "Profil fournisseur",
    "Rayon": "Rayon de paiement",
    "Enregistrer": "Enregistrer",
    "Cnuf": "CNUF De Paiement",
    "CnufCode": "CNUF",
    "LabelCnuf": "Libellé CNUF De Paiement",
    "GDN": "GDN Paiement",
    "GDNCode": "GDN Code",
    "Buyer": "Acheteur",
    "AssistantPrincipal": "Assistant(e)s",
    "Password": "Mot de passe",
    "Fournisseur": "Fournisseur",
    "IflsOrLibelle": "IFLS ou libellé",
    "CNUFOrLibelle": "Recherche par CNUF/libellé CNUF de paiement",
    "ForWhichIAmResponsible": "Dont je suis responsable",
    "Research": "RECHERCHER",
    "Responsable": "Responsable",
    "Edit": "Editer",
    "RayonPlaceholder": "Recherche par rayon de paiement",
    "PaymentLabelPlaceholder": "Recherche par libellé de paiement",
    "FILE_NAME": "_dataobs_informations_fournisseurs"
  },
  "MENU": {
    "RVF": "Remontée des ventes fournisseurs",
    "RECHERCHE_SUPPLIERS": "Recherche Fournisseurs",
    "INFOS_SUPPLIERS": "Information Fournisseurs"
  },
  "export": {
    "paymentSupplierCode": "CNUF de Paiement",
    "paymentSupplierLabel": "Libellé CNUF de Paiement",
    "rayon": "Code Rayon",
    "rayonLabel": "Libellé de Rayon",
    "paymentNegotiationGroupCode": "GDN",
    "paymentNegotiationGroupLabel": "Libellé de GDN",
    "purchaser": "Acheteur",
    "startDate": "Date de Début Campagne ",
    "endDate": "Date de Fin Campagne",
    "annee": "Année"
  },
  "home": {
    "infoV1": "DataObs Statistiques antérieures (uniquement pour le non alimentaire)",
    "infoV2": "DataObs Statistiques à partir des campagnes 2023",
    "toAccess": "Accéder"
  }
}
