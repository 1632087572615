import http from '@/http'
import { inject } from 'vue'
import { handleNetworkError } from '../../../utils/utils'

const state = {
  suppliers: [],
  suppliersInfos: [],
  rvfSales: [],
  suppliersInfosByManager: [],
  negoGroups: [],
  settings: [],
  ifls: [],
  userInfosByEmail: [],
  allRVFData: []
}

const mutations = {
  setSuppliers(state, suppliers) {
    state.suppliers = suppliers
  },
  setNegoGroups(state, negoGroups) {
    state.negoGroups = negoGroups
  },
  setSuppliersInfos(state, data) {
    state.suppliersInfos = data
  },
  setRvfSales(state, data) {
    state.rvfSales = data
  },
  setAllRVFData(state, data) {
    state.allRVFData = data
  },
  setSuppliersInfosByManager(state, data) {
    state.suppliersInfosByManager = data
  },
  setUserInfosByEmail(state, data) {
    state.userInfosByEmail = data
  },
  setSupplierSettings(state, data) {
    state.settings = data
  },
  addSupplierSetting(state, setting) {
    state.settings.push(setting)
  },
  updateSupplierSetting(state, setting) {
    const index = state.settings.findIndex(s => s.extractionid === setting.extractionid)
    state.settings.splice(index, 1, setting)
  },
  deleteSupplierSetting(state, extractionid) {
    const index = state.settings.findIndex(s => s.extractionid === extractionid)
    state.settings.splice(index, 1)
  }
}

const actions = {
  async fetchSuppliers({ state, commit, rootState }, params) {
    let suppliers
    const searchParams = {
      supplier: params?.supplier?.toUpperCase() ?? '',
      rayon: params?.rayon ?? '',
      paymentLabel: params?.paymentLabel?.toUpperCase() ?? '',
      purchaser: params?.purchaser?.toUpperCase() ?? '',
      year: params?.year ?? ''
    }
    try {
      suppliers = await http
        .extend({ headers: { Authorization: `Bearer ${rootState.global.token}` } })
        .get(`supplier/getNPASuppliers`, { searchParams })
        .json()
    } catch (error) {
      console.log('ERREUR', error)
    }
    commit('setSuppliers', suppliers)
  },

  async fetchSuppliersInfos({ state, commit, rootState }, params) {
    let data

    const searchParams = {
      supplier: params?.search?.toUpperCase(),
      rayon: params.rayon,
      uid: params.uid,
      email: params.email,
      year: params.year,
      gdn: params.gdn
    }
    return new Promise(async (resolve, reject) => {
      try {
        data = await http
          .extend({ headers: { Authorization: `Bearer ${rootState.global.token}` } })
          .get(`supplier/npainfos/search`, { searchParams })
          .json()
        commit('setSuppliersInfos', data)
        resolve()
      } catch (error) {
        handleNetworkError(error)
      }
    })
  },

  async fetchAllSuppliersInfos({ state, commit, rootState }) {
    let data
    return new Promise(async (resolve, reject) => {
      try {
        data = await http
          .extend({ headers: { Authorization: `Bearer ${rootState.global.token}` } })
          .get(`supplier/infos`)
          .json()
        commit('setSuppliersInfos', data)
        resolve()
      } catch (error) {
        handleNetworkError(error)
      }
    })
  },

  async fetchSuppliersInfosByManager({ state, commit, rootState }, params) {
    let data

    const searchParams = {
      managerProfile: rootState.global.userInfos.uid
    }
    return new Promise(async (resolve, reject) => {
      try {
        data = await http
          .extend({ headers: { Authorization: `Bearer ${rootState.global.token}` } })
          .get(`supplier/infosByManager`, { searchParams })
          .json()
        commit('setSuppliersInfosByManager', data)
        resolve()
      } catch (error) {
        handleNetworkError(error)
      }
    })
  },

  async fetchRVFSales({ state, commit, rootState }, params) {
    console.log('Find Me')
    let data = []
    const searchParams = {
      fromDate: params.fromDate,
      toDate: params.toDate,
      rayon: params.rayon ? parseInt(params.rayon) : '',
      cnuf: params.cnuf,
      gdnPayment: params.gdnPayment,
      type: params.type,
      year: params.year,
      email: params.email,
      purchaser: params.purchaser,
      frequency: params.frequency
    }
    try {
      let userInfosByEmail = []
      if (rootState.global.userInfos.email) {
        const paramInfos = {
          year: params.year,
          email: rootState.global.userInfos.email
        }

        userInfosByEmail = await http
          .extend({ headers: { Authorization: `Bearer ${rootState.global.token}` } })
          .get(`supplier/npainfosbyemail`, { searchParams: paramInfos })
          .json()
        commit('setUserInfosByEmail', userInfosByEmail)
      }
      data = await http
        .extend({ headers: { Authorization: `Bearer ${rootState.global.token}` } })
        .get(`supplier/supplierSalesRVF`, { searchParams })
        .json()

      // state.ifls.length === 0 ? data = [] : data = data.filter(d => state.ifls.includes(d.substring(0, 5)) )
    } catch (error) {
      handleNetworkError(error)
    }
    commit('setRvfSales', data)
  },

  async downloadFileRVF({ state, commit, rootState }, params) {
    let data
    const searchParams = {
      nameFile: params.nameFile
    }
    try {
      data = await http
        .extend({ headers: { Authorization: `Bearer ${rootState.global.token}` } })
        .get(`supplier/downloadFile`, { searchParams })
        .json()
      return data
    } catch (error) {
      handleNetworkError(error)
    }
  },

  async fetchRVFData({ state, commit, rootState }) {
    try {
      const data = await http
        .extend({ headers: { Authorization: `Bearer ${rootState.global.token}` } })
        .get(`supplier/rvf/data`)
        .json()
      commit('setAllRVFData', data)
    } catch (error) {
      console.error('ERREUR', error)
    }
  },

  async fetchNPASuppliersSettings({ state, commit, rootState }, params) {
    let data
    try {
      data = await http
        .extend({ headers: { Authorization: `Bearer ${rootState.global.token}` } })
        .get(`supplier/${params.supplierCode}/gdn/${params.gdn}/rayon/${params.rayon}/year/${params.year}/NPASettings`)
        .json()
    } catch (error) {
      console.error('NPA_SETTINGS : ', error)
    }

    commit('setSupplierSettings', data)
  },

  updateSuppliersInfos({ state, commit, rootState }, params) {
    const searchParams = {
      email: params.email,
      cnuf: params.cnuf,
      gdn: params.gdn,
      year: params.year,
      updatedBy: rootState.global.userInfos.uid
    }

    return new Promise(async (resolve, reject) => {
      try {
        await http
          .extend({ headers: { Authorization: `Bearer ${rootState.global.token}` } })
          .put(`supplier/updateNPASupplierEmail`, { searchParams })
          .json()
        resolve()
      } catch (error) {
        reject(error)
      }
    })
  }
}

const getters = {
  suppliers(state) {
    return state.suppliers
  },
  negoGroups(state) {
    return state.negoGroups
  },
  suppliersInfos(state) {
    return state.suppliersInfos
  },
  suppliersSetting(state) {
    return state.suppliersSetting
  },
  suppliersInfosBYManager(state) {
    return state.suppliersInfosByManager
  },
  negoGroups(state) {
    return state.negoGroup
  },
  rvfSales(state) {
    return state.rvfSales
  },
  allRVFData(state) {
    return state.allRVFData
  },
  settings(state) {
    return state.settings
  },
  userInfosByEmail(state) {
    return state.userInfosByEmail
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}

export function authorizationRVF(firebaseUser) {
  let data
  const searchParams = {
    email: firebaseUser.email
  }
  return new Promise(async (resolve, reject) => {
    try {
      data = await http
        .extend({ headers: { Authorization: `Bearer ${firebaseUser.accessToken}` } })
        .get(`supplier/authorizationRVF`, { searchParams })
        .json()
      state.ifls = data.map(user => user.ifls).toString()
      resolve(data)
    } catch (error) {
      handleNetworkError(error)
    }
  })
}

export function authorizationExternal(firebaseUser) {
  let data
  const searchParams = {
    email: firebaseUser.email
  }
  return new Promise(async (resolve, reject) => {
    try {
      data = await http
        .extend({ headers: { Authorization: `Bearer ${firebaseUser.accessToken}` } })
        .get(`supplier/authorizationExternalUser`, { searchParams })
        .json()
      state.ifls = data.map(user => user.ifls).toString()
      resolve(data)
    } catch (error) {
      handleNetworkError(error)
    }
  })
}
